import { ComponentPropsWithoutRef } from "react";

function Search(props: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="30"
      height="100%"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m16.325 14.899l5.38 5.38a1.008 1.008 0 0 1-1.427 1.426l-5.38-5.38a8 8 0 1 1 1.426-1.426M10 16a6 6 0 1 0 0-12a6 6 0 0 0 0 12"
      />
    </svg>
  );
}

export default Search;
