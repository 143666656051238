import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["mantineTheme"] */ "/vercel/path0/src/app/mantine-theme.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/src/components/Navbar/navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/utils/analytics.tsx");
