"use client";

import { NavLink } from "./nav-link";
import { ThemeSwitchButton } from "./theme-switch-button";
import { HStack } from "@/styled-system/jsx";
import { center } from "@/styled-system/patterns";

import Link from "next/link";
import SearchBar from "./search-bar";
import { Box, Portal, rem } from "@mantine/core";
import { useAtom } from "jotai";
import { hiddenAtom } from "@/store/atoms";
import { usePathname } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import SignOutButton from "../Button/SignOutButton";
import SignInButton from "../Button/SignInButton";
import Logo from "../Icons/Logo";
import LogoLetter from "../Icons/LogoLetter";
import { useDisclosure } from "@mantine/hooks";

export const DesktopNavBar = ({ session }) => {
  const pathname = usePathname();
  const [page, setPage] = useState(undefined);
  const [hidden] = useAtom(hiddenAtom);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const pageFalse = () => {
      setPage(false);
    };
    const pageTrue = () => {
      setPage(true);
    };

    if (pathname === "/disc-search") {
      pageTrue();
    } else {
      pageFalse();
    }
  });

  return (
    <Portal>
      <Box
        display={{ base: "none", sm: "block" }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: rem(60),
          zIndex: 10,
          transform: `translate3d(0, ${hidden ? 0 : rem(-110)}, 0)`,
          transition: "transform 400ms ease",
        }}
      >
        <HStack
          height="18"
          py="8"
          pr="10"
          pl="10"
          zIndex="1"
          position="relative"
          justify="space-between"
          backdropFilter="auto"
          backdropBlur="sm"
          bg={{ base: "lightAlpha", _dark: "darkAlpha" }}
        >
          <Link
            href="/"
            prefetch={false}
            className={center({ flexShrink: "0" })}
            aria-label="Home Page"
          >
            <Logo />
          </Link>
          {page && <SearchBar />}
          <HStack gap="4">
            <NavLink href="/disc-search" close={close}>
              Search
            </NavLink>
            <NavLink href="/advanced-disc-finder" close={close}>
              Advanced
            </NavLink>
            <NavLink href="/disc-multi-search" close={close}>
              MultiSearch
            </NavLink>
            <NavLink href="/disc-brands" close={close}>
              Brands
            </NavLink>
            {session && (
              <NavLink href="/account" close={close}>
                Account
              </NavLink>
            )}
            {!session ? <SignInButton /> : <SignOutButton />}
            <ThemeSwitchButton />
          </HStack>
        </HStack>
      </Box>
      <Suspense>
        <Box
          display={{ base: "none", sm: "block" }}
          style={{
            zIndex: 1000000,
            position: "absolute",
            top: 30,
            paddingLeft: "70px",
            scale: "150%",
            transform: `translate3d(0, ${!hidden ? 0 : rem(-50)}, 0)`,
            transition: "transform 400ms ease",
          }}
        >
          <Link
            href="/"
            className={center({ flexShrink: "0" })}
            aria-label="Home Page"
          >
            <LogoLetter />
          </Link>
        </Box>
      </Suspense>
    </Portal>
  );
};
